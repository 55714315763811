const FTM = 'ftm'
const BOTH_FTM_CFA = 'both'
const CFA = 'cfa'
const COMET = 'comet'
const CARNMPP = 'carnmpp'
const EUDR = 'eudr'
const NERP = 'nerp'

/**
 * A dictionary of sustainability protocols/data providers.
 */
export const SUSTAINABILITY_PROTOCOLS = {
  FTM,
  BOTH_FTM_CFA,
  CFA,
  COMET,
  CARNMPP,
  EUDR,
  NERP
}
